.title-text{
  color: white;
  font-size: larger;
}


.containerBg{
  background-image:
    linear-gradient(to bottom, rgba(0, 0, 0, 0.52), rgba(0, 0, 0, 0.73));
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
}